@use '../base/variables' as v;

.pokedex{
    width: 100%;
    .pokedex-list{
        overflow-y: scroll; /* Añade la barra de desplazamiento vertical */
        border: .4rem solid v.$white;
        // padding: 0 1.5rem;
        background-color: v.$black;
        height: 55rem;
        ul{
            list-style: none;
            padding: 0;
            li{
                width: 100%;
                padding: 2rem 1rem;
                border-radius: .5rem;
                font-size: 2.4rem;
                display: flex;
                justify-content: space-between;
                // margin-bottom: 2rem;
                align-items: center;
                transition: background-color 0.3s ease;
                border-bottom: 1px solid v.$gray;
                &:hover{
                    background-color: v.$gray;
                }
                p{
                    color: v.$white;
                    font-size: 2.4rem;
                    margin: 0 1rem;
                }
            }
        }
        
    }
    .poke-img{
        border: .4rem solid v.$white;
        background-color: v.$white;
        position: relative;
        display: inline-block;
        img{
            border: .4rem solid v.$black;
            width: 8rem;
            filter: grayscale(100%);
        }
        &::after {
            content: ""; /* Se utiliza un pseudo-elemento para crear el overlay */
            position: absolute;
            top: .4rem;
            left: .4rem;
            width: calc(100% - .8rem);
            height: calc(100% - .8rem);
            background-color: rgba(65, 134, 0, 0.5); /* Color verde con opacidad del 50% */
            pointer-events: none; /* Para permitir la interacción con el contenido del contenedor */
        }
    }
}

.pokedex-list::-webkit-scrollbar {
    width: 12px; /* Ancho de la barra de desplazamiento */
}

.pokedex-list::-webkit-scrollbar-thumb {
    background-color: v.$primary; /* Color de la parte deslizante de la barra */
    // border-radius: .3rem; /* Borde redondeado */
}

.pokedex-list::-webkit-scrollbar-track {
    background-color: v.$lightPrimary; /* Color del fondo de la barra de desplazamiento */
    
}

.poke-info{
    display: flex;
    justify-content: space-between;
    span{
        font-size: 2.8rem;
    }
}


@use 'variables' as v;
@use '../base/mixins' as m;

html {
    box-sizing: border-box;
    font-size: 62.5%;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body{
    font-family: v.$primaryFont;
    font-size: 1.6rem;
    background-color: v.$primary;
}

p{
    font-size: 2.4rem;
    color: v.$black;
}

.container{
    width: 95%;
    max-width: 120rem;
    margin: 0 auto;
    @include m.tablet {
        width: 60%;
    }
    
}

a{
    text-decoration: none;
}

h1, h2, h3{
    margin: 0 0 5rem 0;
}

h1{
    font-size: 4rem;
}

h2{
    font-size: 3.6rem;
}

h3{
    font-size: 2.2rem;
}
  

// Hidden

.hidden{
    display: none !important;
}

.text-align-left{
    text-align: left;
}

@use '../base/mixins' as m;
@use '../base/variables' as v;

.pokedex-options{
    @include m.tablet {
        display: flex;
        justify-content: space-around;
    }
    padding: 1rem 0;
    font-size: 2.2rem;
    select{
        background-color: v.$black;
        color: v.$white;
        border: .3rem solid v.$white;
        padding: 0 1rem;
        margin-bottom: 1rem;
    }
    // option {
        // font-size: 1.6rem;
        // @include m.tablet {
        //     font-size: unset;
        // }
    // }
    .filter-options,
    .sort-options{
        display: grid;
        gap: 1rem;
        @include m.tablet {
            display: unset;
        }
    }


}
@use 'variables' as v;

@mixin phone{
    @media (min-width: v.$phone){
        @content; //aguanta bloques
    }
}

@mixin tablet{
    @media (min-width: v.$tablet){
        @content; 
    }
}

@mixin desktop{
    @media (min-width: v.$desktop){
        @content; 
    }
}

@mixin desktopXl{
    @media (min-width: v.$desktopXl){
        @content; 
    }
}
// lightColors

$primary: #d05030;
$lightPrimary: #f8a050;
$black: #000000;
$gray: #676767;
$white: #f8f8f8;
$green: #58b800;



// Fonts

$primaryFont: 'DotGothic16', sans-serif;

// Media queries

$phone: 480px;
$tablet: 768px;
$desktop: 992px;
$desktopXl: 1600px;






